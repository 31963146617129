var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "jzProfileManage" }, [
    _c("div", { staticStyle: { width: "100%", height: "0.5px" } }),
    _vm._v(" "),
    _c("div", { staticClass: "notice2" }, [
      _vm._v("请退出网站管理状态,再进入该页面。"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "member_basic_info_area g_main_bgColor_v3" }, [
      _c("div", { staticClass: "member_basic_info_v3" }, [
        _c("div", { staticClass: "member_msg_head_pic_v3 icon-pnameIcon" }, [
          _c("img", {
            staticClass: "memberHeadPicV3",
            attrs: { id: "memberHeadPicV3", src: _vm.demoHeadImg },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "member_name_and_level",
            class: { not_auth: !_vm.memberLevelAuthInfo.allow },
          },
          [
            _c("span", { staticClass: "memberName" }, [_vm._v("123")]),
            _vm._v(" "),
            _vm.memberLevelAuthInfo.allow
              ? _c("span", { staticClass: "memberLevelName" }, [
                  _vm._v("[ " + _vm._s(_vm.memberLvName) + " ]"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "modifyArea" }, [
      _vm.integralOpen
        ? _c("li", { attrs: { id: "integral" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("span", { staticClass: "icon-integralIcon" }),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.LS.integral_mine + _vm.itgName) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenKnowPayment
        ? _c("li", { attrs: { id: "knowPayment" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#jzm-vicon_mall" } }),
              ]),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.LS.minePayKnowProducts) },
              }),
            ]),
            _vm._v(" "),
            _vm._m(1),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.openCollection
        ? _c("li", { attrs: { id: "collection" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("span", { staticClass: "newProfileIcon icon-starIcon" }),
              _vm._v(_vm._s(_vm.LS.my_collection)),
            ]),
            _vm._v(" "),
            _vm._m(2),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenMyForm
        ? _c("li", { attrs: { id: "myForm" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("svg", [_c("use", { attrs: { "xlink:href": "#jzm-v27" } })]),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.LS.mobi_member_myForm) },
              }),
            ]),
            _vm._v(" "),
            _vm._m(3),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.memberBulletinOpen
        ? _c("li", { attrs: { id: "latestNews", orderlist: "1" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("span", { staticClass: "icon-noticeIcon" }),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.LS.memberBulletinTitle) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "bulletinTitle",
                attrs: { id: "bulletinTitle", sessionmid: "2" },
              }),
            ]),
            _vm._v(" "),
            _vm._m(4),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isCn && _vm.hasPrize && _vm.isOpenLottery
        ? _c("li", { attrs: { id: "myreward" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("span", { staticClass: "icon-pInfoIcon" }),
              _vm._v(_vm._s(_vm.LS.mobi_member_MyReward)),
            ]),
            _vm._v(" "),
            _vm._m(5),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("li", { attrs: { id: "personalInfo" } }, [
        _c("div", { staticClass: "itemName" }, [
          _c("span", { staticClass: "icon-pInfoIcon" }),
          _vm._v(_vm._s(_vm.LS.mobi_membert_PersonalInfo)),
        ]),
        _vm._v(" "),
        _vm._m(6),
      ]),
      _vm._v(" "),
      _c("li", { attrs: { id: "password" } }, [
        _c("div", { staticClass: "itemName" }, [
          _c("span", { staticClass: "icon-cPswIcon" }),
          _vm._v(_vm._s(_vm.LS.mobi_membert_ResetPassword)),
        ]),
        _vm._v(" "),
        _vm._m(7),
      ]),
      _vm._v(" "),
      _vm.isOtherLogin
        ? _c("li", { attrs: { id: "changeOtherLoginAcct" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("span", { staticClass: "icon-cPswIcon" }),
              _vm._v(_vm._s(_vm.LS.mobi_membert_ChangeOtherLoginAcct)),
            ]),
            _vm._v(" "),
            _vm._m(8),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.clientDelMember
        ? _c("li", { attrs: { id: "clientDelMember" } }, [
            _c("div", { staticClass: "itemName" }, [
              _c("svg", [_c("use", { attrs: { "xlink:href": "#logoff" } })]),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.LS.clientDel || "注销账号"),
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(9),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "onLogout" }, [
      _c("span"),
      _vm._v("\n        " + _vm._s(_vm.LS.mobi_member_onLogout) + "\n    "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-bangding" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }