export function customPopup(options) {
    var defaultOptions = {
        content: '',
        insertSelector: 'body',
    };

    options = jm.extend(defaultOptions, options);

    var id = parseInt(Math.random() * 10000);

    var prelude = "<div id='mobiPrelude" + id + "' class='mobiPrelude'></div>",
        html = "<div id='mobiPrompt" + id + "' class='mobiPrompt'></div>",
        bg = "<div id='mobiPrompt_Bg" + id + "' class='mobiPrompt_Bg' style='opacity: 0.6;'></div>";

    jm(prelude).appendTo(options.insertSelector);
    jm(html).appendTo(options.insertSelector);
    jm(bg).appendTo(options.insertSelector);

    var prompt = jm('#mobiPrompt' + id),
        prompt_Bg = jm('#mobiPrompt_Bg' + id);

    prelude = jm('#mobiPrelude' + id);

    var preludePanel = [];
    preludePanel.push("<div class='mobiPrelude_inner' style='background: transparent;'>");
    if (typeof options.prelude == 'undefined' || options.prelude == null) {
        options.prelude = '';
    }
    preludePanel.push(options.prelude || '');
    preludePanel.push('</div>');
    prelude.append(preludePanel.join(''));

    var tipsPanel = [];
    tipsPanel.push("<div class='mobiPrompt_inner' style='background: transparent; box-shadow: none;'>");
    tipsPanel.push(options.content);
    tipsPanel.push('</div>');
    prompt.append(tipsPanel.join(''));

    if (options.callback) {
        prompt.find('.J-prompt-callback').on('click', function () {
            prelude.removeClass('mobiPreludeShow');
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prelude.remove();
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
            options.callback();
        });
    }

    if (options.cancelCallback) {
        prompt.find('.J-prompt-cancel').on('click', function () {
            prelude.removeClass('mobiPreludeShow');
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prelude.remove();
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
            options.cancelCallback();
        });
    } else {
        prompt.find('.J-prompt-cancel').on('click', function () {
            prelude.removeClass('mobiPreludeShow');
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prelude.remove();
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
        });
    }

    window.setTimeout(function () {
        prompt_Bg.addClass('mobiPromptBgShow');
        prelude.addClass('mobiPreludeShow');
        if (options.mainFrameTimeout) {
            window.setTimeout(function () {
                prelude.removeClass('mobiPreludeShow');
                prelude.remove();
                prompt.addClass('mobiPromptShow');
            }, options.mainFrameTimeout);
        } else {
            prompt.addClass('mobiPromptShow');
        }
    }, 100);
}

export function renderDelMsgConfirm(options) {
    let {
        title = '',
        cancelText = '取消',
        confirmText = '确认',
        insertSelector = '#g_body',
        cancel = () => {},
        confirm = () => {},
    } = options;

    let html = `
        <div style="width:11.95rem; border-radius: 0.1rem;background: #fff;margin: auto;">
            <div style="font-size: 0.7rem;color: #333;border-bottom: 1px solid #e5e5e5;height: 2.925rem;display: flex;align-items: center;justify-content: center;">${title}</div>
            <div style="font-size: 0.6rem;display: flex;height: 1.92rem;">
                <div class="J-prompt-callback" style="color: #666;border-right: 1px solid #e5e5e5;flex-basis: 50%;display: flex;align-items: center;justify-content: center;cursor: pointer;">${cancelText}</div>
                <div class="J-prompt-cancel" style="color: #1677FF;flex-basis: 50%;display: flex;align-items: center;justify-content: center;cursor: pointer;">${confirmText}</div>
            </div>
        </div>
    `;

    customPopup({
        content: html,
        insertSelector,
        callback: () => {
            cancel();
        },
        cancelCallback: () => {
            confirm();
        },
    });
}
