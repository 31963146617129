<template>
    <news-detail-theme3 v-if="openThemeV3"></news-detail-theme3>
    <news-detail-v3 v-else></news-detail-v3>
</template>
<script>
import newsDetailTheme3 from './newsDetailTheme3.vue';
import newsDetailV3 from './newsDetailV3.vue';
import { mapState } from 'vuex';

import { newsDetailMixins } from './mixins';
export default {
    name: 'NewsDetailV3',
    components: {
        newsDetailTheme3,
        newsDetailV3,
    },
    mixins: [newsDetailMixins],
    provide() {
        return {
            moduleId: this.moduleId,
            openThemeV3: this.openThemeV3 || false,
            manageMode: VITE_APP_MODE !== 'visitor',
            style: this.module.style,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
    },
};
</script>

<style lang="scss">
@import '@/styles/preview/newDetail/newDetail.scss';

/* 文章详情 3.0 */

.newsDetailV3Panel .newsDetail {
    overflow: hidden;
}
.newsDetailV3Panel .newsContentPanel {
    padding: 0 0.65rem 0.6rem 0.65rem;
    margin-top: 0.45rem;
    word-wrap: break-word;
    word-break: normal;
}
/*修复文章详情无序列表显示不出来问题*/
.newsDetailV3Panel .newsContentPanel li {
    list-style-type: inherit;
}
.newsDetailV3Panel .newsBack {
    left: 0.45rem;
    top: 0.4rem;
}
.newsDetailV3Panel .newsInfoTitle {
    width: 100%;
    font-size: 0.7rem;
    text-align: left;
    color: #333;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    background: none;
}
.newsDetailV3Panel .newsFigure {
    position: relative;
    background-color: #f6f6f6;
    background-size: cover;
}
.newsDetailV3Panel .newsFigure.icon-noFigure {
    background: url('/image/no-pic2.jpg') no-repeat;
    background-size: 100% 100%;
}
.newsDetailV3Panel .newsCustomPanel {
    height: 0.6rem;
    line-height: 0.6rem;
    display: flex;
    align-items: center;
    margin: 0 0 0.4rem 0;
    font-size: 0.5rem;
    color: #999;
}

.newsDetailV3Panel .newsAuthor {
    max-width: 4.7rem;
    flex-shrink: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.newsDetailV3Panel .newsDate {
    /*max-width: 3.5rem;*/
    overflow: visible;
}
.newsDetailV3Panel div.newsSource {
    max-width: 100%;
    margin-bottom: 0;
}
.newsDetailV3Panel .newsCustomPanel .newsCustom.newsSource {
    margin-right: 0;
}
.newsDetailV3Panel .newsView {
    float: right;
    margin-left: 0.55rem;
    margin-right: 0;
}
.newsDetailV3Panel .newsGroup {
    line-height: 0.8rem;
    margin-bottom: 0.7rem;
}
.newsDetailV3Panel .newsGroup .newsGroupTag {
    display: inline-block;
    height: 0.8rem;
    line-height: 0.8rem;
    min-width: 1rem;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    margin-right: 0.5rem;
    font-size: 0.5rem;
    vertical-align: middle;
    padding: 0rem 0.3rem;
    color: #999;
}
/*新版文章详情底部优化 更改padding: 0; margin-bottom: 1.5rem; 去掉min-height*/
.newsDetailV3Panel div.newsContent {
    /*min-height: 7.5rem;*/
    padding: 0;
    font-size: 0.6rem;
    color: #666;
    line-height: 1.2rem;
    margin-bottom: 1.5rem;
    overflow-x: auto;
}
.newsDetailV3Panel .blankBottom {
    padding: 0;
}
.newsDetailV3Panel .newsContent a {
    text-decoration: underline;
}
.newsDetailV3Panel .newsContent p {
    line-height: 1.2rem;
}
.newsDetailV3Panel .newsLink {
    font-size: 0.5rem;
    color: #333;
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: normal;
}
.newsDetailV3Panel .newsCustomPanel2 {
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.5rem;
    color: #999;
}
.newsDetailV3Panel .newsCustomPanel2 .newsCustom a {
    font-size: 0.5rem;
    color: #4395f5;
    word-wrap: break-word;
    word-break: normal;
}
.newsDetailV3Panel .newsContentPanel .next_separator {
    margin-top: 0.375rem;
}

.newsDetailV3Panel .newsPrev {
    font-size: 0.5rem;
    color: #666;
    margin-top: 0.5rem;
}
.newsDetailV3Panel .newsNext {
    font-size: 0.5rem;
    color: #666;
    margin-top: 0.6rem;
}
.newsDetailV3Panel .prevNextContent {
    width: 81%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666;
}
.newsDetailV3Panel .prevNextContent .newsLink {
    color: #666;
}
.newsDetailV3Panel .prevNextContent .newsLink:visited {
    color: #999;
}
.newsDetailV3Panel .prevNextContent span {
    color: #999;
}
.form .moduleContent .newsNewDetailPanel .newsLink a {
    color: #0591c8;
}

.newsDetailV3Panel .g_separator {
    border-bottom: 1px solid #f0f0f0;
}
.newsDetailV3Panel .commentBox {
    margin: 0;
    padding: 0 0.65rem;
    border-top: 0.25rem solid #fafafa;
}
.newsDetailV3Panel .newsV3ComHeader {
    padding: 1rem 0 0.45rem 0;
    font-size: 0.7rem;
    line-height: 0.7rem;
    text-align: left;
    color: #666;
    box-sizing: border-box;
}
.newsDetailV3Panel .commentBox .msgArea {
    padding: 0.75rem 0 0.9rem 0;
    border-bottom: 1px solid #f0f0f0;
    margin: 0;
}
.newsDetailV3Panel .commentBox .comment:last-child {
    border-bottom: none;
}
.newsDetailV3Panel .msgArea .userIcon {
    width: 1.625rem;
    height: 1.625rem;
    position: absolute;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    background-color: #ccc;
}
.newsDetailV3Panel .msgArea .userIcon img {
    width: 100%;
    height: 100%;
}
.newsDetailV3Panel .msgArea .infoArea {
    padding: 0.25rem 0 0 2.025rem;
}
.newsDetailV3Panel .msgArea .msgTitle {
    height: 0.7rem;
    line-height: 0.7rem;
    margin-bottom: 0.15rem;
}
.newsDetailV3Panel .msgArea .msgUser {
    max-width: 30%;
    height: 0.8rem;
    line-height: 0.7rem;
    margin: 0 0;
    font-size: 0.6rem;
    color: #666;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
}
.newsDetailV3Panel .msgArea .creatorType {
    margin-left: 5px;
    font-size: 0.5rem;
    color: #999;
    display: inline-block;
    vertical-align: top;
    opacity: 1;
}
.newsDetailV3Panel .msgArea .msgTime {
    height: 0.7rem;
    line-height: 0.7rem;
    font-size: 0.5rem;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: static;
}

.newsDetailV3Panel .msgArea {
    .msgBottom {
        .time {
            font-size: 0.512rem;
            color: #b3b3b3;
        }
        .msgDel {
            font-size: 0.512rem;
            margin-left: 0.4267rem;
            color: #999;
        }
    }
}

.newsDetailV3Panel .msgArea .msgContent {
    font-size: 0.6rem;
    line-height: 1rem;
    color: #333;
}

.newsDetailV3Panel .newsBottom {
    height: 2.3rem;
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    border-top: 0.025px solid #dadada;
    background-color: #fff;
}
.newsDetailV3Panel .postComment {
    height: 1.4rem;
    line-height: 1.4rem;
    margin: 0.45rem 0.65rem;
    background-color: #f5f5f5;
    border-radius: 0.8rem;
    color: #999;
}
.newsDetailV3Panel .newsBottom .icon-postComm {
    font-size: 0.9rem;
    position: relative;
    top: 0.15rem;
}
.newsDetailV3Panel .newsBottom .icon-postComm:before {
    content: '\e91b';
}

.getMoreComments {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.65rem;
    color: #666;
    margin: 0 0.45rem;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    text-align: center;
}
#g_body.noScroll {
    overflow-y: hidden;
    max-height: 100%;
}

/*.newsDetailV3Panel .attachList{ padding: 0.5rem 0 0.5rem 0.5rem;}*/
.newsDetailV3Panel div.attachListItem {
    width: auto;
    height: 1.5rem;
    background-color: #f4f4f4;
}
.newsDetailV3Panel .attachFileType {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
}
.newsDetailV3Panel .fileType-iconDoc {
    background: url(/image/comm/fai/filetype_word.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconPpt {
    background: url(/image/comm/fai/filetype_ppt.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconXls {
    background: url(/image/comm/fai/filetype_excel.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconPdf {
    background: url(/image/comm/fai/filetype_pdf.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconJpg {
    background: url(/image/comm/fai/filetype_image.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconTxt {
    background: url(/image/comm/fai/filetype_txt.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconUnknown {
    background: url(/image/comm/fai/filetype_unknown.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconAvi {
    background: url(/image/comm/fai/filetype_avi.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconRar {
    background: url(/image/comm/fai/filetype_rar.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconRm {
    background: url(/image/comm/fai/filetype_rm.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileType-iconZip {
    background: url(/image/comm/fai/filetype_zip.png?v=201711250607) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
}
.newsDetailV3Panel .fileContent {
    display: inline-block;
    padding-left: 0.5rem;
    vertical-align: top;
}
.newsDetailV3Panel .fileContent .fileContentName {
    font-size: 0.6rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10.5rem;
}
.newsDetailV3Panel .fileContent .fileContentSize {
    font-size: 0.5rem;
    line-height: 0.8rem;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10.5rem;
}
/* 3.15rem -> 0.97rem qjie*/
.newsDetailV3Panel .title-textStyle {
    padding: 3.15rem 0.65rem 0rem 0.65rem;
    position: relative;
}
.newsDetailV3Panel .title-textStyleFix {
    padding: 0.97rem 0.65rem 0rem 0.65rem;
    position: relative;
}
.newsDetailV3Panel .title-imgStyle {
    padding: 0.75rem 0.65rem 0rem 0.65rem;
    position: relative;
}
.newsDetailV3Panel .displayClamp2Style {
    min-height: 1rem;
    word-break: normal;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
.newsDetailV3Panel .pagenation .pagePrev {
    margin: 0.6rem 2.5rem 0.5rem 0;
}
.newsDetailV3Panel .pagenation .pageNext {
    margin: 0.6rem 0 0.5rem 2.5rem;
}
.newsDetailV3Panel .pagenation .pagePrev:before {
    content: '上一页';
    width: 3.5rem;
    height: 1.3rem;
    line-height: 1.3rem;
    margin-right: 0;
    font-size: 0.6rem;
    font-family: 'icomoon', '微软雅黑';
}
.newsDetailV3Panel .pagenation .pageNext:before {
    content: '下一页';
    width: 3.5rem;
    height: 1.3rem;
    line-height: 1.3rem;
    margin-left: 0;
    font-size: 0.6rem;
    font-family: 'icomoon', '微软雅黑';
}
.newsDetailV3Panel .newsCustomPanel2 .newsShare {
    height: 0.5rem;
    line-height: 0.5rem;
    float: right;
    display: inline-block;
    cursor: pointer;
}
.newsDetailV3Panel .newsCustomPanel2 .newsShare span {
    font-family: '微软雅黑';
}
#newsDetailV3Share {
    display: none;
}
.newsDetailV3Panel .newsCustomPanel2 .icon-share:before {
    content: '\eab3';
    font-size: 0.5rem;
    margin-right: 0.2rem;
}
.newsNewDetailPanel .newsInfoTitleStyle {
    padding: 3rem 0.8rem 0rem 0.45rem;
}
.newsNewDetailPanel.is_no_head_tool .productDetailHeader {
    display: none;
}
.newsNewDetailPanel.is_no_head_tool .title-textStyle,
.newsNewDetailPanel.is_no_head_tool .newsInfoTitle {
    padding-top: 0.45rem;
}
.newsDetailV3Panel .attachListViewIcon {
    display: inline-block;
}
.newsDetailV3Panel .attachListViewIcon .jzm-vicon_preview {
    width: 1.3rem;
    height: 1.3rem;
}
.newsNewDetailPanel .titleNoWrap .textTitle {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.newsNewDetailPanel .subTitleNoWrap {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.newsDetailV3Panel .attachListViewIcon .jzm-vicon_preview {
    width: 1.3rem;
    height: 1.3rem;
    stroke: #666;
}
/* 文章详情 3.0 */
</style>
